import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { LayoutViewModel } from '../models/layout-view.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  cdnPath = '';
  currentYear: number = new Date().getFullYear()
  isBusinessNetwork: boolean;
  layoutView: LayoutViewModel;
  businessFAQLink: boolean = false;

  constructor(private appService: AppService) {
  }

  ngOnInit(): void {
    this.layoutView = this.appService.layoutView;
    this.cdnPath = this.appService.layoutView.cdnPath;
    this.isBusinessNetwork = this.appService.layoutView.isBusinessNetwork;
    if (this.isBusinessNetwork) {
      this.businessFAQLink = true;
    }
  }

  businessFAQ(event: MouseEvent) {
    event.preventDefault();
    const cultureCode = this.appService.cultureCode;
    let url = this.appService.layoutView.supplierRegistrationLink;
    const baseUrl = new URL(url).origin;
    window.open(baseUrl + `/BusinessNetwork/FAQ?culture=${cultureCode}#/BN-FAQ`, '_blank');
  }
}
