<div class="text-center medium">
    <div class="text-center my-0 mt-2" *ngIf="!businessFAQLink">
      {{'FOOTER.NeedHelpEmail' |translate}} 
      <a [href]="layoutView.supportGroupEmail" attr.aria-label="{{'FOOTER.NeedHelpEmail' |translate}} support@gep.com">support@gep.com</a>
    </div>
    <div class="text-center my-2 mt-2" *ngIf="businessFAQLink">
      {{'FOOTER.SupplierGuideLinesLink' |translate}} 
      <a target="_blank" (click)="businessFAQ($event)" href="#" role="link" aria-level="1" >click here</a>
    </div>
    <div class="text-center mb-1 ">
        <a [href]="layoutView.privacyStatementLink" target="_blank">{{'FOOTER.PrivacyStatement' |translate}}</a>
        <span class="gepProductLogoSeperator align-self-center mx-2 " style="width: 4px; height: 4px;"></span>
        <a [href]="layoutView.termsOfUseLink" target="_blank">{{'FOOTER.TermsOfUse' |translate}}</a>
    </div>
    <div class="d-flex justify-content-center mt-0" >
      <div class="mt-1 px-1">
            <span class = "softwarelogo mx-1"><img src="{{this.cdnPath}}/assets/images/nexxe-logo.svg"> </span>
            <span class="gepProductLogoSeperator mx-2 my-0">   </span>
            <span class = "softwarelogo mx-1"><img src="{{this.cdnPath}}/assets/images/smart-logo.svg"> </span>
            <span class="gepProductLogoSeperator mx-2 my-0"></span>
            <span class = "softwarelogo mx-1"> <img src="{{this.cdnPath}}/assets/images/click-logo.svg"> </span>
          </div>
  </div>
    <div class="text-center mt-0 mb-0">
        &copy; {{'FOOTER.Copyright' |translate}} GEP {{currentYear}}
    </div>
</div>
