import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ResetPasswordService } from "./reset-password.service";
import { PreResetPasswordModel } from "../models/pre-reset-password.model";
import { AppService } from "../app.service";

@Injectable({
  providedIn: "root",
})
export class ResetPasswordGuard implements CanActivate {
  constructor(
    private resetPasswordService: ResetPasswordService,
    private router: Router,
    private appService: AppService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = next.queryParams.token;
    if (!token) {
      //?
    }
    const request: PreResetPasswordModel = {
      token: token,
    };
    return this.resetPasswordService.preResetPassword(request).pipe(
      map((e) => {
        if (e.isOtpSent) {
          this.appService.reRequestVerificationCodeSeconds = e.reRequestVerificationCodeSeconds;
          return true;
        } else {
          // how to show error here?
        }
      })
    );
  }
}
