import { Router, } from "@angular/router";
import { map } from "rxjs/operators";
import { ResetPasswordService } from "./reset-password.service";
import { AppService } from "../app.service";
import * as i0 from "@angular/core";
import * as i1 from "./reset-password.service";
import * as i2 from "@angular/router";
import * as i3 from "../app.service";
export class ResetPasswordGuard {
    constructor(resetPasswordService, router, appService) {
        this.resetPasswordService = resetPasswordService;
        this.router = router;
        this.appService = appService;
    }
    canActivate(next, state) {
        const token = next.queryParams.token;
        if (!token) {
            //?
        }
        const request = {
            token: token,
        };
        return this.resetPasswordService.preResetPassword(request).pipe(map((e) => {
            if (e.isOtpSent) {
                this.appService.reRequestVerificationCodeSeconds = e.reRequestVerificationCodeSeconds;
                return true;
            }
            else {
                // how to show error here?
            }
        }));
    }
}
ResetPasswordGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResetPasswordGuard_Factory() { return new ResetPasswordGuard(i0.ɵɵinject(i1.ResetPasswordService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AppService)); }, token: ResetPasswordGuard, providedIn: "root" });
